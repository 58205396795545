/**
 * Checks to see if file exists. Returns a truthy value if it does. A falsy value otherwise.
 * @param path path of file
 */
export const tryRequire = (path: string) => {
  try {
    const temp = require(`${path}`);
    console.log(`image ${path} found.`);
    return temp;
  } catch (err) {
    console.log(`image ${path} not found.`);
    return null;
  }
};

export const delay = (ms: number): Promise<void> => new Promise(res => setTimeout(res, ms));
