const _roundTwoDecimals = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;
const _roundOneDecimal = (num: number) => Math.round((num + Number.EPSILON) * 10) / 10;

export const extractFloat = (num: string) => {
  const temp = num.match(/\d|\./g);
  if (!temp || !temp.length) {
    return 0.0;
  }
  return parseFloat(temp.join(''));
};

export const extractFloatAndRoundToBillion = (num: string) => {
  const temp = num.match(/\d/g);
  if (!temp || !temp.length) {
    return 0.0;
  }
  const temp2 = parseFloat(temp.join(''));
  return _roundOneDecimal(temp2 / 1000);
};

export const extractFloatAndConditionallyRoundToBillion = (num: string) => {
  const temp = num.match(/\d/g);
  if (!temp || !temp.length) {
    return 0.0;
  }
  const temp2 = parseFloat(temp.join(''));

  if (temp2 >= 1000) {
    return `${_roundOneDecimal(temp2 / 1000)}B`;
  }

  return `${temp2}mm`;
};

export const numberToCurrencyMorB = (num: string | number): string => {
  if (!!!num) {
    return;
  }

  if (Number(num) >= 1000 && Number(num) < 1000000) {
    return `$${Math.round(Number(num) / 1000).toLocaleString()}k`;
  }

  if (Number(num) >= 1000000 && Number(num) < 1000000000) {
    return `$${Math.round(Number(num) / 1000000).toLocaleString()}mm`;
  }

  if (Number(num) >= 1000000000) {
    return `$${Math.round(Number(num) / 1000000000).toLocaleString()}B`;
  }

  return `$${Number(num).toLocaleString()}`;
};
