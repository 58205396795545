import { useEffect, useMemo, useRef, useState } from 'react';
import lodash from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import ReactSlider from 'react-slider';
import styled from 'styled-components';
import { Base10Logo } from '../svg/base10logo';
import theme from '../util/theme';
import { prismicClient, Prismic } from '../services';
import { mobileSize } from '../util/variables';
import useMediaQuery from '../util/useMediaQuery';
import Fonts from '../fonts/Fonts';
import { FadingText } from '../components/ui';
import ESGModal from '../components/Modal/ESGModal';
import favicon from '../content/img/icon/favicon.ico';
import { delay } from '../util/misc';

type FormStep = {
  title: any;
  label: any;
  value: boolean;
  relation: {
    description: any;
    relations: string[];
  };
  resource: {
    title: any;
    description: any;
    image: any;
    link: any;
  };
  data_point: {
    company: any;
    description: any;
  };
};

const ArrowDown = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-arrow-down"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      stroke-width={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 5l0 14"></path>
      <path d="M18 13l-6 6"></path>
      <path d="M6 13l6 6"></path>
    </svg>
  );
};

const CircleCheck = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-circle-check-filled"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      stroke-width={2}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
        strokeWidth={0}
        fill="inherit"
      ></path>
    </svg>
  );
};

const CircleArrowRight = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-circle-arrow-right-filled arrow-right"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      stroke-width={2}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M12 2l.324 .005a10 10 0 1 1 -.648 0l.324 -.005zm.613 5.21a1 1 0 0 0 -1.32 1.497l2.291 2.293h-5.584l-.117 .007a1 1 0 0 0 .117 1.993h5.584l-2.291 2.293l-.083 .094a1 1 0 0 0 1.497 1.32l4 -4l.073 -.082l.064 -.089l.062 -.113l.044 -.11l.03 -.112l.017 -.126l.003 -.075l-.007 -.118l-.029 -.148l-.035 -.105l-.054 -.113l-.071 -.111a1.008 1.008 0 0 0 -.097 -.112l-4 -4z"
        strokeWidth={0}
        fill="currentColor"
      ></path>
    </svg>
  );
};

const Thumb = (props: any, state: any) =>
  (state?.index === 0 && (
    <StyledThumb {...props} index={state?.index} value={state?.valueNow}>
      <div style={{ color: 'black', padding: '36px 2px', lineHeight: 1.1, textAlign: 'center' }}>
        {state?.valueNow}
      </div>
    </StyledThumb>
  )) || <div></div>;

const Track = (props: any, state: any) => <StyledTrack {...props} index={state?.index} />;

const StripeImage = () => <img src={require(`../content/img/esg/ico_stripe.png`)} />;
const PaypalImage = () => <img src={require(`../content/img/esg/ico_paypal.png`)} />;
const AirBNBImage = () => <img src={require(`../content/img/esg/ico_airbnb.png`)} />;
const NetflixImage = () => <img src={require(`../content/img/esg/ico_netflix.png`)} />;

const arraysEqualWithoutValue = (arr1: Record<string, any>[], arr2: Record<string, any>[]) => {
  let clonedArr1 = lodash.cloneDeep(arr1);
  let clonedArr2 = lodash.cloneDeep(arr2);

  clonedArr1.forEach(item => {
    item.steps.forEach((step: Record<string, any>) => {
      delete step.value;
    });
  });
  clonedArr2.forEach(item => {
    item.steps.forEach((step: Record<string, any>) => {
      delete step.value;
    });
  });

  return JSON.stringify(clonedArr1) === JSON.stringify(clonedArr2);
};

const findStepBySlug = (slug: string, items: Record<string, any>[]) => {
  for (let i = 0; i < items.length; i++) {
    for (let j = 0; j < items[i].steps.length; j++) {
      if (items[i].steps[j].slug === slug) {
        return {
          overallIndex: i,
          stepIndex: j,
          step: items[i].steps[j],
        };
      }
    }
  }
  return null;
};

export default function BestManagedCompaniesPlaybook() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/esg/base10-hero-bg.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [_, slug] = typeof window !== 'undefined' ? window.location.href.split('#') : [];

  const [formStep, setFormStep] = useState<number>(0);
  const [formState, setFormState] = useState<
    | {
        value: any;
        steps: FormStep[];
      }[]
    | undefined
  >(undefined);
  const [pageContent, setPageContent] = useState<any>(null);
  const [brandLogos, setBrandLogos] = useState<any>(null);
  const [modalState, setModalState] = useState<any>(undefined);
  const formRef = useRef<null | HTMLDivElement>(null);

  const modalData = useMemo(() => {
    if (modalState) {
      return formState?.[modalState.index]?.steps[modalState.stepIndex];
    }
    return null;
  }, [modalState, formState]);

  const countCompletedTasks = (data: any) => {
    let trueCount = 0;
    let totalSteps = 0;

    for (const obj of data) {
      const steps = obj.steps || [];

      for (const step of steps) {
        if (step.value === true) {
          trueCount++;
        }
        totalSteps++;
      }
    }

    return { trueCount, totalSteps };
  };

  const completedTasks = formState && countCompletedTasks(formState);

  const sliderValue = completedTasks
    ? (completedTasks?.trueCount / completedTasks?.totalSteps) * 100
    : 0;

  const isMobile = useMediaQuery(mobileSize);

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query(
        Prismic.Predicates.at('document.type', 'esg-marketing'),
        { pageSize: 9999 },
      );
      if (response && response.results.length) {
        setPageContent(response.results[0]?.data);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    //if localstorage pull from that
    const savedForm = localStorage.getItem('esg-marketing-form');

    const savedValues =
      savedForm !== 'undefined' && savedForm !== null ? JSON.parse(savedForm) : [];
    if (pageContent?.form) {
      const findStepRelations = (item: any) => {
        const trueBooleansArray = [];

        for (const key in item) {
          if (item[key] === true) {
            trueBooleansArray.push(key);
          }
        }
        return trueBooleansArray;
      };
      const transformedData: any = [];
      const modalData: any = [];

      pageContent?.form.forEach((item: any) => {
        const stepName = item.step_name[0]?.text;
        const stepTask = item.step_task[0]?.text;
        const stepSlug = item.step_slug[0]?.text;

        const step = {
          title: stepName,
          label: stepTask,
          slug: stepSlug,
          value: false, //checkmark
          relation: {
            description: item?.step_relation_description[0]?.text,
            relations: findStepRelations(item),
          },
          resource: {
            title: item?.step_resource_title[0]?.text,
            description: item?.step_resource_description[0]?.text,
            image: item?.step_resource_image?.url,
            link: item?.step_resource_link?.url,
          },
          data_point: {
            company: item?.data_point_company[0]?.text,
            description: item?.data_point_description[0]?.text,
          },
        };

        const stepIndex = transformedData.findIndex(
          (data: { value: any }) => data.value === stepName,
        );

        if (stepIndex === -1) {
          transformedData.push({
            value: stepName,
            steps: [step],
          });
        } else {
          transformedData[stepIndex]?.steps?.push(step);
        }
        modalData.push(step);
      });

      if (arraysEqualWithoutValue(transformedData, savedValues)) {
        setFormState(savedValues);
      } else {
        setFormState(transformedData);
      }
    }
    if (pageContent?.logos) {
      const transformedData: any = [];
      pageContent?.logos.forEach((item: any) => {
        const brand = {
          name: item?.brand[0]?.text,
          logo: item?.logo?.url,
        };
        transformedData.push(brand);
      });
      setBrandLogos(transformedData);
    }
  }, [pageContent]);

  useEffect(() => {
    if (formState) {
      localStorage.setItem('esg-marketing-form', JSON.stringify(formState));
    }
  }, [formState]);

  useEffect(() => {
    if (slug && formState?.length) {
      const result = findStepBySlug(slug, formState);
      if (result) {
        formRef.current?.scrollIntoView({ behavior: 'smooth' });
        delay(1500).then(() => {
          setFormStep(result.overallIndex);
          setModalState({ index: result.overallIndex, stepIndex: result.stepIndex });
        });
      }
    }
  }, [slug, formState]);

  const Mark = (props: any) => {
    switch (props?.key) {
      case 0:
        return (
          <span
            {...props}
            style={{
              ...props.style,
              backgroundColor: 'transparent',
              border: '3px solid #45AEEB66',
              borderRadius: 50,
              height: 28,
              marginLeft: -7,
              top: '50%',
              transform: 'translateY(-50%)',
              width: 28,
              zIndex: -1,
            }}
          ></span>
        );

      case 90:
        return (
          <StyledMark
            {...props}
            className={`${props.className} ${sliderValue === 90 ? 'fade' : ''}`}
            style={{ color: '#00000033', marginLeft: '-8px', ...props.style }}
          >
            {props?.key}
          </StyledMark>
        );
      case 91:
        return (
          <MarkImage
            {...props}
            style={{
              backgroundColor: 'transparent',
              height: 24,
              width: 24,
              marginTop: -10,
              ...props.style,
            }}
          >
            <StripeImage />
          </MarkImage>
        );
      case 93:
        return (
          <MarkImage
            {...props}
            className={`${props.className} modile-hidden`}
            style={{
              backgroundColor: 'transparent',
              height: 24,
              width: 24,
              marginTop: -10,
              ...props.style,
            }}
          >
            <PaypalImage />
          </MarkImage>
        );
      case 97:
        return (
          <MarkImage
            {...props}
            className={`${props.className} modile-hidden`}
            style={{
              backgroundColor: 'transparent',
              height: 24,
              width: 24,
              marginTop: -10,
              ...props.style,
            }}
          >
            <AirBNBImage />
          </MarkImage>
        );
      case 99:
        return (
          <MarkImage
            {...props}
            style={{
              backgroundColor: 'transparent',
              height: 24,
              width: 24,
              marginTop: -10,
              ...props.style,
            }}
          >
            <NetflixImage />
          </MarkImage>
        );
      default:
        return (
          <StyledMark
            {...props}
            {...props}
            className={`${props.className} ${sliderValue === 100 ? 'fade' : ''}`}
            style={{ color: '#00000033', ...props.style }}
          >
            {props?.key}
          </StyledMark>
        );
    }
  };

  const findLogoFromName = (name: string) => {
    const foundLogo = brandLogos?.find((item: any) => item?.name === name);
    return foundLogo?.logo;
  };

  const handleCheckboxClick = (value: boolean, label: string, checked: boolean) => {
    setFormState(prevData => {
      const updatedData = prevData?.map(item => {
        if (item.value === value) {
          const updatedSteps = item.steps.map(step => {
            if (step.label === label) {
              return {
                ...step,
                value: checked,
              };
            }
            return step;
          });
          return {
            ...item,
            steps: updatedSteps,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  return (
    <Main>
      <Helmet>
        <title>Base10's Best-Managed Companies Playbook</title>
        <meta name="title" content="Base10's Best-Managed Companies Playbook" />
        <meta
          name="description"
          content="Base10 guides you through using your mission, transparency, and governance to achieve better financial outcomes"
        />

        <link rel="icon" href={favicon} />
        <meta name="robots" content="noindex" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href="https://base10.vc/best-managed-companies-playbook/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://base10.vc/best-managed-companies-playbook/" />
        <meta property="og:title" content="Base10's Best-Managed Companies Playbook" />
        <meta
          property="og:description"
          content="Base10 guides you through using your mission, transparency, and governance to achieve better financial outcomes"
        />
        <meta property="og:image" content="/img/social-playbook.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://base10.vc/best-managed-companies-playbook/" />
        <meta property="twitter:title" content="Base10's Best-Managed Companies Playbook" />
        <meta
          property="twitter:description"
          content="Base10 guides you through using your mission, transparency, and governance to achieve better financial outcomes"
        />
        <meta property="twitter:image" content="/img/social-playbook.png" />
      </Helmet>

      <Fonts />
      <HeaderContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {!isMobile && (
          <Img
            fluid={data.file.childImageSharp.fluid}
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              right: 0,
              bottom: 0,
              zIndex: -1,
            }}
            imgStyle={{ objectFit: 'cover' }}
          />
        )}
        <div style={{ padding: '30px 24px', marginRight: 'auto' }}>
          <a href="/">
            <Base10Logo color={'#000000'} />
          </a>
        </div>
        <MediaQueryPadding>
          <HeaderCaption>{pageContent?.header_caption?.[0]?.text}</HeaderCaption>

          <HeaderMain
            interval={2000}
            texts={pageContent?.header_title?.map(
              (item: any) => item?.header_title_variant?.[0]?.text,
            )}
          />

          <Description>{pageContent?.header_description?.[0]?.text}</Description>
        </MediaQueryPadding>
        <MediaQueryPadding style={{ paddingTop: 0 }}>
          <BannerButton
            className="button"
            onClick={() => formRef.current?.scrollIntoView({ behavior: 'smooth' })}
          >
            <Description>See your next steps</Description> <ArrowDown />
          </BannerButton>
        </MediaQueryPadding>
      </HeaderContainer>

      <HeaderCaption
        style={{ textAlign: 'center', padding: '8px 0px' }}
        dangerouslySetInnerHTML={{ __html: pageContent?.brand_title?.[0]?.text }}
      />

      <LogoContainer>
        <img src={pageContent?.brands_image?.url} alt="top companies" />
      </LogoContainer>

      <TestimonialContainer>
        <TestimoninalFlexBox>
          {/* <MediaQueryPadding overflow> */}
          <SubHeader
            dangerouslySetInnerHTML={{ __html: pageContent?.testimonial_header?.[0]?.text }}
          />
          {/* </MediaQueryPadding> */}
          <TestimonialRow>
            {/* <Slider {...settings}> */}
            {pageContent?.testimonials.map((item: any, index: number) => (
              <TabContainer key={index}>
                <TabContainerContent>
                  <TabTitle>{item?.testimonial_brand?.[0]?.text}</TabTitle>
                  <div>{item?.testimonial_description?.[0]?.text}</div>
                </TabContainerContent>
                <TestimonialImage>
                  <img
                    src={findLogoFromName(item?.testimonial_brand[0]?.text)}
                    alt="company logo"
                  />
                </TestimonialImage>
              </TabContainer>
            ))}
            {/* </Slider> */}
          </TestimonialRow>
        </TestimoninalFlexBox>
      </TestimonialContainer>
      <form>
        <MediaQueryPadding>
          <FormContainer ref={formRef}>
            <div style={{ padding: '0px 0px 53px 0px', display: 'relative' }}>
              <SliderCaption>Best in class</SliderCaption>
              <StyledSlider
                marks={[90, 100, 0, 91, 93, 97, 99]}
                defaultValue={[0]}
                value={[sliderValue, 90]}
                renderTrack={Track}
                renderThumb={Thumb}
                renderMark={Mark}
              />
            </div>
            <div id="playbook">
              <FormHeader>
                <HeaderCaption>{pageContent?.form_caption?.[0]?.text}</HeaderCaption>
                <Header>{pageContent?.form_header?.[0]?.text}</Header>
                <Description style={{ marginBottom: 48 }}>
                  {pageContent?.form_description?.[0]?.text}
                </Description>
              </FormHeader>
              <FormContentContainer>
                <StepContainer>
                  {formState?.map((item: any, index: number) => (
                    <div style={{ width: '100%' }} key={item?.label}>
                      <Step
                        selected={index === formStep}
                        onClick={e => {
                          e.preventDefault();
                          setFormStep(index);
                          localStorage.setItem('esg-marketing-step', index.toString());
                        }}
                      >
                        {item?.value}
                      </Step>
                      {!!isMobile && (
                        <MobileStepDescriptionContainer>
                          {formStep === index &&
                            formState[formStep]?.steps?.map((item: any, idx: any) => {
                              return (
                                <StepDescription key={`${item?.label}_mobile`}>
                                  <StepDescriptionRadioContainer>
                                    <CheckboxInput
                                      type="checkbox"
                                      id={item?.label}
                                      isActive={Boolean(item?.value)}
                                      checked={item?.value}
                                      onChange={e => {
                                        handleCheckboxClick(
                                          formState[formStep]?.value,
                                          item?.label,
                                          e.target.checked,
                                        );
                                      }}
                                    />
                                    {!!item?.value && (
                                      <Checkmark width={28} height={28} fill={'#45AEEB'} />
                                    )}
                                  </StepDescriptionRadioContainer>
                                  <label
                                    onClick={e => {
                                      e.preventDefault();
                                      setModalState({ index: formStep, stepIndex: idx });
                                    }}
                                    htmlFor={item?.label}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {item?.label}
                                  </label>
                                </StepDescription>
                              );
                            })}
                        </MobileStepDescriptionContainer>
                      )}
                    </div>
                  ))}
                </StepContainer>
                {!isMobile && (
                  <StepDescriptionContainer>
                    {formState &&
                      formState[formStep]?.steps?.map((item: any, idx: number) => {
                        return (
                          <StepDescription
                            key={`${item?.label}_web`}
                            onClick={() =>
                              handleCheckboxClick(
                                formState[formStep]?.value,
                                item?.label,
                                !item?.value,
                              )
                            }
                          >
                            <StepDescriptionRadioContainer>
                              <CheckboxInput
                                isActive={Boolean(item?.value)}
                                type="checkbox"
                                id={item?.label}
                                checked={item?.value}
                              />
                              {!!item?.value && (
                                <Checkmark width={28} height={28} fill={'#45AEEB'} />
                              )}
                            </StepDescriptionRadioContainer>
                            <label htmlFor={item?.label} style={{ pointerEvents: 'none' }}>
                              {item?.label}
                            </label>

                            <CircleArrowRight
                              width={24}
                              height={24}
                              onClick={(e: any) => {
                                setModalState({ index: formStep, stepIndex: idx });
                                e.stopPropagation();
                              }}
                            />
                          </StepDescription>
                        );
                      })}
                  </StepDescriptionContainer>
                )}
              </FormContentContainer>
            </div>
          </FormContainer>
        </MediaQueryPadding>
      </form>

      <ESGModal
        findLogoFromName={findLogoFromName}
        formState={formState}
        formStep={formStep}
        handleCheckboxClick={handleCheckboxClick}
        modalData={modalData}
        modalState={modalState}
        setFormStep={setFormStep}
        setModalState={setModalState}
      />
    </Main>
  );
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  padding-bottom: 185px;
  width: 100%;
  font-family: ${theme.TERTIARY_FONT};
`;

const HeaderContainer = styled.div`
  padding-bottom: 64px;

  @media ${mobileSize} {
    padding-bottom: 0;
    align-items: center;
    text-align: center;
  }
`;
const BannerButton = styled.button`
  appearance: none;
  align-items: center;
  background-color: #343434;
  border-radius: 14px;
  border: none;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.24);
  color: #ffffff;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  gap: 8px;
  padding: 16px;
`;

const HeaderMain = styled(FadingText)`
  font-size: 48px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;

  @media ${mobileSize} {
    font-size: 32px;

    > div {
      align-items: center;
      justify-content: center;
      line-height: 1;
    }
  }
`;

const Header = styled.div`
  font-size: 32px;
  line-height: 1;
`;

const HeaderCaption = styled.div`
  color: #00000080;
  font-family: ${theme.QUATERNARY_FONT};
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;

  @media ${mobileSize} {
    white-space: pre-line;
  }
`;

const SubHeader = styled.div`
  font-family: ${theme.QUATERNARY_FONT};
  font-size: 32px;
  padding: 0 100px;
  white-space: pre-line;
  line-height: 1;
  @media ${mobileSize} {
    font-size: 24px;
    padding: 0 32px;
  }
`;

const Description = styled.div`
  font-size: 20px;
  max-width: 620px;
  line-height: 1.2;

  @media ${mobileSize} {
    font-size: 16px;
  }
`;

const LogoContainer = styled.div`
  margin: auto;
  max-width: 100vw;
  overflow: scroll;

  img {
    max-height: 82px;
  }
`;
const TestimonialContainer = styled.div`
  background-color: #45aeeb;
  color: #ffffff;
`;

const TestimoninalFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 128px;
  padding: 100px 0;

  @media ${mobileSize} {
    gap: 64px;
    padding: 50px 0 0px 0;
  }
`;

const TestimonialRow = styled.div`
  display: flex;
  gap: 28px;
  padding: 0 100px;

  @media ${mobileSize} {
    overflow: scroll;
    padding: 0 32px;
    padding-bottom: 50px;
  }
`;

const TabTitle = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;

  @media ${mobileSize} {
    margin-bottom: 16px;
  }
`;

const TestimonialImage = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  img {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
`;

const TabContainer = styled.div`
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 14px;
  color: #000000b2;
  display: flex;
  flex: 1;
  padding: 24px;
  position: relative;

  @media ${mobileSize} {
    flex: 0 0 auto;
    width: 268px;
  }
`;

const TabContainerContent = styled.div`
  width: 75%;

  @media ${mobileSize} {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormContentContainer = styled.div`
  border-radius: 14px;
  border: 1px solid #45aeeb66;
  display: flex;
  flex-direction: row;
  flex-basis: calc(100% / 2);
  justify-content: space-between;
`;

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 14px;
  pointer-events: none;

  .modile-hidden {
    @media ${mobileSize} {
      display: none;
    }
  }
`;

const StyledThumb = styled.div<{ index: number; value: number }>`
  background-color: #000;
  border-radius: 50%;
  color: white;
  font-family: ${theme.QUATERNARY_FONT};
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  line-height: 1;
  top: 50%;
  transform: translate(${props => (props.value === 0 ? '-5px' : '0')}, -50%);
  transition: left 0.8s ease-in;
  width: 24px;

  @media ${mobileSize} {
    top: calc(50% + 0.3px);
  }
`;

const StyledTrack = styled.div<{ index: Number }>`
  top: 0;
  bottom: 0;
  background: ${props =>
    props.index === 2
      ? '#45AEEB'
      : props.index === 1
      ? '#ddd'
      : 'linear-gradient(270deg, #45AEEB 71.88%, #317DA9 100%);'};
  border-radius: 999px;
  transition: 0.8s ease-in;
`;

const StyledMark = styled.span`
  background: transparent;
  color: black;
  display: inline-block;
  font-family: ${theme.QUATERNARY_FONT};
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  margin-top: 29px;
  transition: all 0.8s ease-in;

  &.fade {
    opacity: 0;
  }
`;

const MarkImage = styled.span`
  img {
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }
`;

const SliderCaption = styled.div`
  font-family: ${theme.QUATERNARY_FONT};
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: right;
  width: 100%;
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  border-radius: 14px;
  width: 100%;

  @media ${mobileSize} {
    gap: 0px;
  }
`;

const Step = styled.button<{ selected: boolean }>`
  border-radius: 14px;
  background-color: ${props => (props.selected ? '#45AEEB' : '#45AEEB1A')};
  border: 1px solid #45aeeb66;
  padding: 32px 24px;
  width: 100%;
  max-width: ${props => (props.selected ? '453px' : '421px')};
  text-align: left;
  color: ${props => (props.selected ? 'white' : 'rgba(0,0,0,.7)')};
  transition: all 0.2s ease-in;
  box-shadow: ${props => (props.selected ? '0px 4px 16px 0px #0000003D;' : '')};
  font-size: 24px;
  @media ${mobileSize} {
    font-size: 20px;
    max-width: 100%;
    line-height: 1.1;
    transition: max-width 0.2s ease-in;
    margin-bottom: ${props => (props.selected ? '32px' : '0px')};
  }
`;

const StepDescriptionContainer = styled.div`
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: scroll;
  padding: 78px 24px;
  width: 100%;
  max-height: 420px;
`;

const MobileStepDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding-bottom: 16px;
  overflow-y: scroll;
  margin-bottom: 16px;
  cursor: pointer;
`;
const StepDescription = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 24px;
  margin-left: 4px;
  position: relative;
  padding: 12px 16px;
  border-radius: 14px;
  transition: all 0.2s;
  align-items: center;

  .arrow-right {
    cursor: pointer;
    color: #45aeeb;
    flex-shrink: 0;
    opacity: 0;
    transition: all 0.2s;
    margin-left: auto;
  }

  &:hover {
    box-shadow: 0px 0px 5px 0px #0000001f;

    @media ${mobileSize} {
      box-shadow: none;
    }

    .arrow-right {
      opacity: 1;
    }
  }
`;

const StepDescriptionRadioContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

const MediaQueryPadding = styled.div<{ overflow?: boolean }>`
  padding: 100px;
  @media ${mobileSize} {
    padding: ${props => (props.overflow ? '45px 0px 0px 32px' : '45px 32px')};
  }
`;

const CheckboxInput = styled.input<{ isActive?: boolean }>`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: ${({ isActive }) => (isActive ? '#ffffff' : '#f2f2f3')};
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  min-width: 24px;
  width: 24px;
`;

const Checkmark = styled(CircleCheck)`
  position: absolute;
  color: white;
  pointer-events: none;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
