import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { mobileSize } from '../../util/variables';
import { useEffect } from 'react';
import theme from '../../util/theme';

const relationsMap: Record<string, any> = {
  chief_marketing_officer: {
    backgroundColor: '#E95C5633',
    image: require(`../../content/img/esg/chief-marketing-officer.svg`),
    label: 'Chief Marketing Officer',
  },
  chief_executive_officer: {
    backgroundColor: '#7155A433',
    image: require(`../../content/img/esg/chief-executive-officer.svg`),
    label: 'Chief Executive Officer',
  },
  chief_financial_officer: {
    backgroundColor: '#57BC5033',
    image: require(`../../content/img/esg/chief-financial-officer.svg`),
    label: 'Chief Financial Officer',
  },
  chief_human_resources_officer: {
    backgroundColor: '#EF7C4933',
    image: require(`../../content/img/esg/chief-human-resources-officer.svg`),
    label: 'Chief Human Resources Officer',
  },
  general_counsel: {
    backgroundColor: '#3378F533',
    image: require(`../../content/img/esg/general-counsel.svg`),
    label: 'General Counsel',
  },
  board_of_directors: {
    backgroundColor: '#FAD84933',
    image: require(`../../content/img/esg/board-of-directors.svg`),
    label: 'Board of Directors',
  },
  chief_operating_officer: {
    backgroundColor: '#F15BB533',
    image: require(`../../content/img/esg/chief-operating-officer.svg`),
    label: 'Chief Operating Officer',
  },
  chief_diversity_officer: {
    backgroundColor: '#25CED133',
    image: require(`../../content/img/esg/chief-diversity-officer.svg`),
    label: 'Chief Diversity Officer',
  },
  chief_revenue_officer: {
    backgroundColor: '#61D0954D',
    image: require(`../../content/img/esg/chief-revenue-officer.svg`),
    label: 'Chief Revenue Officer',
  },
};

const CircleCheck = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-circle-check-filled"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
        strokeWidth={0}
        fill="currentColor"
      ></path>
    </svg>
  );
};

const ArrowLeft = (props: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_822_1397)">
        <path
          d="M17.333 20L13.333 16L17.333 12"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M28 16C28 14.4241 27.6896 12.8637 27.0866 11.4078C26.4835 9.95189 25.5996 8.62902 24.4853 7.51472C23.371 6.40042 22.0481 5.5165 20.5922 4.91345C19.1363 4.31039 17.5759 4 16 4C14.4241 4 12.8637 4.31039 11.4078 4.91345C9.95189 5.5165 8.62902 6.40042 7.51472 7.51472C6.40042 8.62902 5.5165 9.95189 4.91345 11.4078C4.31039 12.8637 4 14.4241 4 16C4 19.1826 5.26428 22.2348 7.51472 24.4853C9.76516 26.7357 12.8174 28 16 28C19.1826 28 22.2348 26.7357 24.4853 24.4853C26.7357 22.2348 28 19.1826 28 16Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_822_1397">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowRight = (props: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_822_1393)">
        <path
          d="M14.667 12L18.667 16L14.667 20"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 16C4 17.5759 4.31039 19.1363 4.91345 20.5922C5.5165 22.0481 6.40042 23.371 7.51472 24.4853C8.62902 25.5996 9.95189 26.4835 11.4078 27.0866C12.8637 27.6896 14.4241 28 16 28C17.5759 28 19.1363 27.6896 20.5922 27.0866C22.0481 26.4835 23.371 25.5996 24.4853 24.4853C25.5996 23.371 26.4835 22.0481 27.0866 20.5922C27.6896 19.1363 28 17.5759 28 16C28 12.8174 26.7357 9.76516 24.4853 7.51472C22.2348 5.26428 19.1826 4 16 4C12.8174 4 9.76516 5.26428 7.51472 7.51472C5.26428 9.76516 4 12.8174 4 16Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_822_1393">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowDoubleLeft = (props: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_822_1401)">
        <path
          d="M20 20L16 16L20 12"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14.667 20L10.667 16L14.667 12"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M27.9997 16C27.953 12.833 26.6563 9.81296 24.392 7.59821C22.1278 5.38346 19.0799 4.15368 15.9126 4.17699C12.7454 4.20031 9.71588 5.47483 7.48452 7.72267C5.25316 9.97052 4.00098 13.0094 4.00098 16.1767C4.00098 19.344 5.25316 22.3828 7.48452 24.6307C9.71588 26.8785 12.7454 28.153 15.9126 28.1763C19.0799 28.1997 22.1278 26.9699 24.392 24.7551C26.6563 22.5404 27.953 19.5203 27.9997 16.3533V16Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_822_1401">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ArrowDoubleRight = (props: any) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_822_1388)">
        <path
          d="M12 12L16 16L12 20"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M17.333 12L21.333 16L17.333 20"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4 16C4.04663 19.167 5.34342 22.187 7.60763 24.4018C9.87184 26.6165 12.9198 27.8463 16.087 27.823C19.2542 27.7997 22.2838 26.5252 24.5152 24.2773C26.7465 22.0295 27.9987 18.9906 27.9987 15.8233C27.9987 12.656 26.7465 9.61719 24.5152 7.36934C22.2838 5.1215 19.2542 3.84697 16.087 3.82366C12.9198 3.80034 9.87184 5.03013 7.60763 7.24488C5.34342 9.45963 4.04663 12.4797 4 15.6467V16Z"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_822_1388">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const CircleArrowUpRight = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-circle-arrow-up-right-filled"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      {...props}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2 4.66h-6l-.117 .007a1 1 0 0 0 -.883 .993l.007 .117a1 1 0 0 0 .993 .883h3.584l-4.291 4.293l-.083 .094a1 1 0 0 0 1.497 1.32l4.293 -4.293v3.586l.007 .117a1 1 0 0 0 1.993 -.117v-6l-.007 -.117l-.029 -.149l-.035 -.105l-.054 -.113l-.071 -.111a1.01 1.01 0 0 0 -.097 -.112l-.09 -.08l-.096 -.067l-.098 -.052l-.11 -.044l-.112 -.03l-.126 -.017l-.075 -.003z"
        stroke-width="0"
        fill="currentColor"
      ></path>
    </svg>
  );
};

const renderRelation = (relation: string) => {
  const rel = relationsMap?.[relation];
  if (!rel) return null;

  return (
    <RelationsDiv style={{ backgroundColor: rel.backgroundColor }}>
      <img src={rel.image} />
      <span>{rel.label}</span>
    </RelationsDiv>
  );
};

type ESGModalProps = {
  findLogoFromName: any;
  formState: any;
  formStep: any;
  handleCheckboxClick: any;
  modalData: any;
  modalState: any;
  setModalState: any;
  setFormStep: any;
};

const ESGModal = ({
  findLogoFromName,
  formState,
  formStep,
  handleCheckboxClick,
  modalData,
  modalState,
  setModalState,
  setFormStep,
}: ESGModalProps) => {
  const isOpen = modalData && formState;

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen]);

  const onDoubleLeftClick = () => {
    if (modalState && formState) {
      if (formState[modalState?.index - 1]?.steps[0]) {
        setModalState({ index: modalState?.index - 1, stepIndex: 0 });
        setFormStep(modalState?.index - 1);
      }
    }
  };

  const onLeftClick = () => {
    if (modalState && formState) {
      if (formState[modalState?.index]?.steps[modalState?.stepIndex - 1]) {
        setModalState((prev: any) => ({
          ...prev,
          stepIndex: modalState?.stepIndex - 1,
        }));
        return;
      }

      if (formState[modalState?.index - 1]?.steps[0]) {
        let prevStep = formState[modalState?.index - 1];
        setModalState({
          index: modalState?.index - 1,
          stepIndex: prevStep.steps.length - 1,
        });
        setFormStep(modalState?.index - 1);
        return;
      }
    }
  };

  const onRightClick = () => {
    if (modalState && formState) {
      if (formState[modalState?.index]?.steps[modalState?.stepIndex + 1]) {
        setModalState((prev: any) => ({
          ...prev,
          stepIndex: modalState?.stepIndex + 1,
        }));
        return;
      }

      if (formState[modalState?.index + 1]?.steps[0]) {
        setModalState({ index: modalState?.index + 1, stepIndex: 0 });
        setFormStep(modalState?.index + 1);
        return;
      }
    }
  };

  const onDoubleRightClick = () => {
    if (modalState && formState) {
      if (formState[modalState?.index + 1]?.steps[0]) {
        setModalState({ index: modalState?.index + 1, stepIndex: 0 });
        setFormStep(modalState?.index + 1);
      }
    }
  };

  const allActionItems = formState?.reduce((acc: any, cur: any) => [...acc, ...cur.steps], []);

  const currentActionItem =
    allActionItems?.findIndex((item: any) => item.label === modalData?.label) + 1;

  return isOpen
    ? createPortal(
        <div
          style={{
            backgroundColor: '#000000CC',
            height: '100%',
            inset: 0,
            position: 'fixed',
            top: 0,
            width: '100%',
            zIndex: 10,
          }}
          onClick={() => {
            setModalState(undefined);
          }}
        >
          <ModalContainer onClick={e => e.stopPropagation()}>
            <ModalWrapper>
              <ModalContent>
                <ModalStep
                  as="label"
                  htmlFor={`${modalData?.label}_modal`}
                  style={{
                    border: '1px solid',
                    borderColor: modalData?.value ? 'transparent' : 'rgba(0,0,0,0.3)',
                    backgroundColor: modalData?.value ? '#45AEEB' : '#FFFFFF',
                    color: modalData?.value ? '#FFFFFF' : '#00000080',
                    borderRadius: 14,
                  }}
                >
                  <CheckboxInput
                    type="checkbox"
                    id={`${modalData?.label}_modal`}
                    checked={modalData?.value}
                    onChange={e => {
                      handleCheckboxClick(
                        formState[formStep]?.value,
                        modalData?.label,
                        e.target.checked,
                      );
                    }}
                  />
                  {!!modalData?.value && <ModalCheckmark width={29} height={29} />}
                  <div> {modalData?.value ? 'Completed' : 'To do'}</div>
                </ModalStep>

                <ModalNavigation>
                  <div style={{ marginRight: 24, fontSize: 14, color: '#00000080' }}>
                    {currentActionItem} of {allActionItems?.length} action items
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 12,
                    }}
                  >
                    <ArrowDoubleLeft
                      onClick={onDoubleLeftClick}
                      style={{ cursor: 'pointer', opacity: modalState?.index === 0 ? 0.3 : 1 }}
                    />

                    <ArrowLeft
                      onClick={onLeftClick}
                      style={{
                        cursor: 'pointer',
                        opacity: modalState?.index === 0 && modalState?.stepIndex === 0 ? 0.3 : 1,
                      }}
                    />

                    <ArrowRight
                      onClick={onRightClick}
                      style={{
                        cursor: 'pointer',
                        opacity:
                          modalState?.index === formState.length - 1 &&
                          formState[formState.length - 1]?.steps.length - 1 ===
                            modalState?.stepIndex
                            ? 0.3
                            : 1,
                      }}
                    />

                    <ArrowDoubleRight
                      onClick={onDoubleRightClick}
                      style={{
                        cursor: 'pointer',
                        opacity: modalState?.index === formState.length - 1 ? 0.3 : 1,
                      }}
                    />
                  </div>
                </ModalNavigation>

                <ModalBody style={{ flexDirection: 'column' }}>
                  <div>
                    <div
                      style={{
                        color: '#00000080',
                        fontFamily: theme.TERTIARY_FONT,
                        fontSize: 18,
                        fontWeight: 400,
                        lineHeight: 1.1,
                        marginBottom: 23,
                      }}
                    >
                      {formState[modalState?.index]?.value}&nbsp;{' '}
                      <span style={{ color: 'rgba(0,0,0,.2)' }}>{' /'}</span>
                    </div>
                    <ModalLabelText>{modalData?.label}</ModalLabelText>
                  </div>
                  {modalData?.relation?.description && (
                    <ResourceContainer>
                      <ResourceHeader>{modalData?.relation?.description}</ResourceHeader>

                      <div
                        style={{
                          alignItems: 'flex-start',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          gap: 16,
                        }}
                      >
                        {modalData?.relation?.relations?.map((relation: string) =>
                          renderRelation(relation),
                        )}
                      </div>
                    </ResourceContainer>
                  )}

                  {modalData?.resource?.title || modalData?.data_point?.description ? (
                    <ModalBody2>
                      {modalData?.resource?.title && (
                        <ModalContentContainer>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              gap: 24,
                              fontFamily: theme.QUATERNARY_FONT,
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                color: '#00000080',
                                fontSize: 14,
                                fontWeight: 500,
                                textTransform: 'uppercase',
                              }}
                            >
                              Resource
                            </div>

                            <ResourceWrapper
                              isFullWidth={!Boolean(modalData?.data_point?.description)}
                            >
                              {modalData?.resource?.image && (
                                <div
                                  style={{
                                    borderRadius: '14px',
                                    overflow: 'hidden',
                                  }}
                                >
                                  <img
                                    alt="resource"
                                    src={modalData?.resource?.image}
                                    style={{ height: 'auto', width: '100%' }}
                                  />
                                </div>
                              )}
                              <div
                                style={{
                                  flex: 1,
                                  gap: 16,
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <ModalBodyHeader
                                  style={{
                                    color: '#000000B2',
                                    lineHeight: 'normal',
                                  }}
                                >
                                  {modalData?.resource?.title}
                                </ModalBodyHeader>
                                {modalData?.resource?.description && (
                                  <div
                                    style={{
                                      color: '#00000080',
                                      fontSize: 16,
                                      fontWeight: 400,
                                      lineHeight: 'normal',
                                    }}
                                  >
                                    {modalData?.resource?.description}
                                  </div>
                                )}

                                {modalData?.resource?.link && (
                                  <a
                                    style={{
                                      color: '#45AEEB',
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      fontSize: 16,
                                      fontWeight: 400,
                                      textDecoration: 'none',
                                      lineHeight: 1,
                                      gap: 4,
                                    }}
                                    href={modalData?.resource?.link}
                                    target="_blank"
                                  >
                                    Read more <CircleArrowUpRight fill="#45AEEB" opacity={0.4} />
                                  </a>
                                )}
                              </div>
                            </ResourceWrapper>
                          </div>
                        </ModalContentContainer>
                      )}
                      {modalData?.data_point?.description && (
                        <DataPointContainer>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div
                              style={{
                                color: '#45AEEB',
                                fontSize: 14,
                                fontWeight: 500,
                                textTransform: 'uppercase',
                              }}
                            >
                              Data Point
                            </div>
                            {findLogoFromName(modalData?.data_point?.company) ? (
                              <TestimonialImage>
                                <img
                                  src={findLogoFromName(modalData?.data_point?.company)}
                                  alt="company logo"
                                />
                              </TestimonialImage>
                            ) : null}
                          </div>
                          <div
                            style={{
                              color: 'rgba(0,0,0,0.7)',
                              fontSize: 28,
                              fontWeight: 400,
                              lineHeight: 'normal',
                              fontFamily: theme.QUATERNARY_FONT,
                              padding: '24px 0',
                            }}
                          >
                            {modalData?.data_point?.description}
                          </div>
                          <div />
                        </DataPointContainer>
                      )}
                    </ModalBody2>
                  ) : null}
                </ModalBody>
              </ModalContent>
            </ModalWrapper>
          </ModalContainer>
        </div>,
        document.body,
      )
    : null;
};

export default ESGModal;

const ModalContainer = styled.div`
  left: 50%;
  padding: 0 48px;
  position: fixed;
  top: 50%;
  max-width: 1058px;
  transform: translate(-50%, -50%);

  width: 100%;

  @media ${mobileSize} {
    height: 100%;
    max-height: 92vh;
    padding: 0;
    top: 8vh;
    transform: translate(-50%, 0%);
    width: 100%;
  }
`;

const ModalWrapper = styled.div`
  background-color: #ffffff;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-height: 80vh;
  overflow: hidden;
  position: relative;
  width: 100%;
  width: 100%;

  @media ${mobileSize} {
    border-radius: 14px 14px 0 0;
    height: 100%;
    max-height: unset;
    width: 100%;
  }
`;

const ModalContent = styled.div`
  display: grid;
  height: 100%;
  padding: 48px;
  overflow: scroll;
  grid-template-areas:
    'step navigation'
    'body body';

  @media ${mobileSize} {
    padding: 24px;
    padding-bottom: 122px;
    grid-template-rows: max-content;
    grid-template-areas:
      'step step'
      'body body';
  }
`;

const ModalNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
  grid-area: navigation;
  font-family: ${theme.TERTIARY_FONT};

  @media ${mobileSize} {
    background-color: #ffffff;
    border-top: 1px solid #0000001f;
    bottom: 0;
    box-shadow: 0px -1px 4px #0000001f;
    justify-content: space-between;
    left: 0;
    padding: 20px 24px;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
`;

const ModalBodyHeader = styled.div`
  font-weight: 400;
  font-size: 24px;

  @media ${mobileSize} {
    font-size: 20px;
  }
`;

const ResourceHeader = styled.div`
  font-family: ${theme.QUATERNARY_FONT};
  font-size: 24px;
  font-weight: 400;
  padding-left: 16px;

  @media ${mobileSize} {
    font-size: 20px;
    padding-left: 0;
  }
`;

const ModalLabelText = styled.div`
  font-family: ${theme.TERTIARY_FONT};
  font-size: 40px;
  font-weight: 400;
  line-height: 1.1;

  @media ${mobileSize} {
    font-size: 32px;
  }
`;

const ModalBody = styled.div`
  margin-top: 64px;
  display: flex;
  gap: 48px;
  grid-area: body;
  @media ${mobileSize} {
    margin-top: 48px;
    gap: 24px;
  }
`;

const ModalBody2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 48px;
  @media ${mobileSize} {
    flex-direction: column;
    gap: 24px;
  }
`;

const ModalContentContainer = styled.div`
  align-items: flex-start;
  border-radius: 14px;
  border: 1px solid #cacacb80;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  padding: 32px;
  width: 100%;

  @media ${mobileSize} {
    flex-direction: column;
  }
`;

const ResourceContainer = styled.div`
  align-items: center;
  border-radius: 14px;
  border: 1px solid #cacacb80;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
  overflow: hidden;
  padding: 16px;
  width: 100%;

  @media ${mobileSize} {
    padding: 24px;
    flex-direction: column;
    align-items: start;
  }
`;

const ResourceWrapper = styled.div<{ isFullWidth: boolean }>`
  align-items: flex-start;
  display: flex;
  flex-direction: ${({ isFullWidth }) => (isFullWidth ? 'row' : 'column')};
  gap: ${({ isFullWidth }) => (isFullWidth ? '92px' : '24px')};

  @media ${mobileSize} {
    flex-direction: column;
    gap: 24px;
  }
`;

const DataPointContainer = styled.div`
  background-color: #ecf7fd;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  font-family: ${theme.QUATERNARY_FONT};
  justify-content: space-between;
  padding: 32px;
  position: relative;
  width: 100%;
`;

const ModalStep = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  align-items: center;
  box-shadow: 0px 4px 16px 0px #0000003d;
  grid-area: step;
  width: fit-content;
  height: fit-content;
  font-family: ${theme.TERTIARY_FONT};
`;

const ModalCheckmark = styled(CircleCheck)`
  color: white;
  position: absolute;
  pointer-events: none;
`;

const RelationsDiv = styled.div`
  border-radius: 14px;
  display: inline-flex;
  flex-direction: row;
  font-family: ${theme.TERTIARY_FONT};
  font-size: 20px;
  font-weight: 400;
  gap: 10px;
  padding: 10px;
  span {
    text-wrap: nowrap;
  }

  @media ${mobileSize} {
    span {
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const CheckboxInput = styled.input<{ checked?: boolean }>`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: ${({ checked }) => (checked ? 'transparent' : '#f2f2f3')};
  border-radius: 50%;
  cursor: pointer;
  height: 24px;
  min-width: 24px;
  width: 24px;
`;

const TestimonialImage = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;

  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
  }
`;
